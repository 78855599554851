const myTheme = {
  // Alert
  alert: {
    base: "p-4 pl-12 relative rounded-lg leading-5",
    withClose: "pr-12",
    success: "bg-green-50 text-green-900 dark:bg-green-600 dark:text-white",
    danger: "bg-red-50 text-red-900 dark:bg-red-600 dark:text-white",
    warning: "bg-yellow-50 text-yellow-900 dark:bg-yellow-600 dark:text-white",
    neutral: "bg-slate-50 text-slate-800 dark:bg-slate-700 dark:text-slate-300",
    info: "bg-blue-50 text-blue-900 dark:bg-blue-600 dark:text-white",
    icon: {
      base: "h-5 w-5",
      success: "text-green-400 dark:text-green-300",
      danger: "text-red-400 dark:text-red-300",
      warning: "text-yellow-400 dark:text-yellow-100",
      neutral: "text-slate-400 dark:text-slate-500",
      info: "text-blue-400 dark:text-blue-300",
    },
  },
  // Pagination
  pagination: {
    base: "flex flex-col justify-between text-xs sm:flex-row text-slate-600 dark:text-slate-400",
  },
  // TableFooter
  tableFooter: {
    base: "px-4 py-3 border-t border-slate-200 dark:border-slate-700 bg-white text-slate-500 dark:text-slate-400 dark:bg-slate-800",
  },
  // TableRow
  tableRow: {
    base: "",
  },
  // TableHeader
  tableHeader: {
    base: "text-xs font-semibold tracking-wide text-left text-slate-500 uppercase border-b border-slate-200 dark:border-slate-700 bg-slate-100 dark:text-slate-400 dark:bg-slate-800",
  },
  // TableContainer
  tableContainer: {
    base: "w-full overflow-hidden border border-slate-200 dark:border-slate-700 rounded-lg ring-1 ring-black ring-opacity-5",
  },
  // TableCell
  tableCell: {
    base: "px-4 py-3",
  },
  // TableBody
  tableBody: {
    base: "bg-white divide-y divide-slate-100 dark:divide-slate-700 dark:bg-slate-800 text-slate-700 dark:text-slate-400",
  },
  // DropdownItem
  // this is the <li> that lives inside the Dropdown <ul>
  // you're probably looking for the dropdownItem style inside button
  dropdownItem: {
    base: "mb-2 last:mb-0",
  },
  // Dropdown
  dropdown: {
    base: "absolute w-56 p-2 mt-2 text-slate-600 bg-white dark:bg-slate-800 dark:border-slate-700 border border-slate-100 rounded-lg shadow-md min-w-max-content",
    align: {
      left: "left-0",
      right: "right-0",
    },
  },
  // Avatar
  avatar: {
    base: "relative rounded-full inline-block",
    size: {
      large: "w-10 h-10",
      regular: "w-8 h-8",
      small: "w-6 h-6",
    },
  },
  // Modal
  modal: {
    base: "w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-slate-800 sm:rounded-lg sm:m-4 sm:max-w-xl custom-modal",
  },
  // ModalBody
  modalBody: {
    base: "mb-6 text-sm text-slate-700 dark:text-slate-400",
  },
  // ModalFooter
  modalFooter: {
    base: "flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-3 sm:space-y-0 sm:space-x-4 sm:flex-row bg-slate-50 dark:bg-slate-800",
  },
  // ModalHeader
  modalHeader: {
    base: "mt-4 mb-2 text-lg font-semibold text-slate-700 dark:text-slate-300",
  },
  // Badge
  badge: {
    base: "inline-flex px-2 text-xs font-medium leading-5 rounded-full",
    success:
      "text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-100",
    danger: "text-red-500 bg-red-100 dark:text-red-100 dark:bg-red-800",
    warning: "text-yellow-500 bg-yellow-100 dark:text-white dark:bg-yellow-600",
    neutral:
      "text-slate-500 bg-slate-100 dark:text-slate-100 dark:bg-slate-800",
    primary: "text-blue-500 bg-blue-100 dark:text-white dark:bg-blue-800",
  },
  // Backdrop
  backdrop: {
    base: "fixed inset-0 z-40 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center",
  },
  // Textarea
  textarea: {
    base: "block w-full text-sm dark:text-slate-300 rounded-md focus:outline-none form-textarea",
    active:
      "focus:border-purple-400 border-slate-300 dark:border-slate-600 dark:focus:border-slate-600 dark:bg-slate-700 dark:focus:ring-slate-300 focus:ring focus:ring-purple-300",
    disabled: "cursor-not-allowed opacity-50 bg-slate-300 dark:bg-slate-800",
    valid:
      "border-green-600 dark:bg-slate-700 focus:border-green-400 dark:focus:border-green-400 focus:ring focus:ring-green-200 dark:focus:ring-green-200",
    invalid:
      "border-red-600 dark:bg-slate-700 focus:border-red-400 dark:focus:border-red-400 focus:ring focus:ring-red-200 dark:focus:ring-red-200",
  },
  // Select
  select: {
    base: "block w-full px-2 py-1 text-sm dark:text-slate-300 focus:outline-none rounded-md form-select",
    active:
      "focus:border-slate-200 border-slate-200 dark:border-slate-600 focus:shadow-none focus:ring focus:ring-green-300 dark:focus:border-slate-500 dark:focus:ring-slate-300 dark:bg-slate-700",
    select: "leading-5",
    disabled: "cursor-not-allowed opacity-50 bg-slate-300 dark:bg-slate-800",
    valid:
      "border-green-600 dark:bg-slate-700 focus:border-green-400 dark:focus:border-green-400 focus:ring focus:ring-green-200 dark:focus:ring-green-200",
    invalid:
      "border-red-600 dark:bg-slate-700 focus:border-red-400 dark:focus:border-red-400 focus:ring focus:ring-red-200 dark:focus:ring-red-200",
  },
  // Label
  label: {
    base: "block text-sm text-slate-700 dark:text-slate-400",
    // check and radio get this same style
    check: "inline-flex items-center",
    disabled: "opacity-50 cursor-not-allowed",
  },
  // Input
  // valid: focus:ring focus:ring-green-300
  input: {
    base: "block w-full px-3 py-1 text-sm focus:outline-none dark:text-slate-300 leading-5 rounded-md",
    active:
      "focus:border-slate-200 border-slate-200 dark:border-slate-600 focus:ring focus:ring-green-300 dark:focus:border-slate-500 dark:focus:ring-slate-300 dark:bg-slate-700",
    disabled: "cursor-not-allowed opacity-50 bg-slate-300 dark:bg-slate-800",
    valid:
      "border-green-600 dark:bg-slate-700 focus:border-green-400 dark:focus:border-green-400 focus:ring focus:ring-green-200 dark:focus:ring-green-200",
    invalid:
      "border-red-600 dark:bg-slate-700 focus:border-red-400 dark:focus:border-red-400 focus:ring focus:ring-red-200 dark:focus:ring-red-200",
    radio:
      "text-green-500 form-radio focus:border-green-400 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-0 dark:focus:ring-slate-300",
    checkbox:
      "text-green-500 form-checkbox focus:border-green-500 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-0 rounded dark:focus:ring-slate-300",
  },
  // HelperText
  helperText: {
    base: "text-xs",
    valid: "text-green-600 dark:text-green-400",
    invalid: "text-red-600 dark:text-red-400",
  },
  // Card
  card: {
    base: "min-w-0 rounded-lg  overflow-hidden",
    // ring-1 ring-black ring-opacity-4
    default: "bg-dark dark:bg-slate-800",
    // bg-white
  },
  cardBody: {
    base: "p-4",
  },
  // Button
  button: {
    base: "align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-bold focus:outline-none",
    block: "w-full",
    size: {
      larger: "px-10 py-4 rounded-lg",
      large: "px-5 py-3 rounded-lg",
      regular: "px-4 py-2 rounded-lg text-sm",
      small: "px-3 py-1 rounded-md text-sm",
      icon: {
        larger: "p-4 rounded-lg",
        large: "p-3 rounded-lg",
        regular: "p-2 rounded-lg",
        small: "p-2 rounded-md",
      },
      pagination: "px-3 py-1 rounded-md text-xs",
    },
    // styles applied to the SVG icon
    icon: {
      larger: "h-5 w-5",
      large: "h-5 w-5",
      regular: "h-5 w-5",
      small: "h-3 w-3",
      left: "mr-2 -ml-1",
      right: "ml-2 -mr-1",
    },
    primary: {
      base: "text-ged font-bold text-base border border-transparent" /*text-white bg-green-500 border border-transparent*/,
      active:
        "active:bg-white   focus:ring-purple-300 " /*'active:bg-white hover:text-black hover:bg-green-600 focus:ring-purple-30*/,
      disabled: "opacity-50 cursor-not-allowed",
    },
    outline: {
      base: "text-slate-600 border-slate-200 border dark:text-slate-400 focus:outline-none",
      active:
        "rounded-lg border border-slate-200 px-4 w-full mr-3 flex items-center justify-center cursor-pointer h-12 bg-slate-200",
      disabled: "opacity-50 cursor-not-allowed bg-slate-300",
    },
    link: {
      base: "text-slate-600 dark:text-slate-400 focus:outline-none border border-transparent",
      active:
        "active:bg-transparent hover:bg-slate-100 focus:ring focus:ring-slate-300 dark:hover:bg-slate-500 dark:hover:text-slate-300 dark:hover:bg-opacity-10",
      disabled: "opacity-50 cursor-not-allowed",
    },
    // this is the button that lives inside the DropdownItem
    dropdownItem: {
      base: "inline-flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-slate-100 hover:text-slate-800 dark:hover:bg-slate-800 dark:hover:text-slate-200",
    },
  },
};
export default myTheme;
